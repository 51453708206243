import React from "react"
import "../components/styles.css"
import Navbar from "../components/Navbar"
import Sidemenu from "../components/Sidemenu"
import Icons from "../components/Icons"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../components/Header"

const Home = () => {
  const data = useStaticQuery(
    graphql`
      query {
        info: markdownRemark(frontmatter: { title: { eq: "Home Page" } }) {
          frontmatter {
            title
            heading
            description {
              desc
              subdesc
            }
          }
        }
      }
    `
  )

  return (
    <>
      <SEO title="ICON" />
      <Header />
      <section className="container-fluid home position-relative">
        <div className="container h-100">
          <Sidemenu />
          <Navbar />
          <div className="row h-75 text-white">
            <div className="col-lg-5 col-md-5 col-sm-12 d-flex flex-column justify-content-end justify-content-md-center">
              <h1 className="text-uppercase pb-2 mt-sm-5 mt-xs-0">
                Innovation and <br /> Excellence.
              </h1>
              <div
                className=""
                data-sal="slide-down"
                data-sal-duration="2000"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <p className="content">
                  {data.info.frontmatter.description.desc}
                </p>

                <p className="mb-lg-5 mt-md-2 content">
                  {data.info.frontmatter.description.subdesc}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-home row w-100 text-white mb-3">
            <div className="col-md-8 col-9">
              <small className="footer-note">
                All Rights Reserved Icon Maritime Technology &amp; Consulting
                LLC
              </small>
            </div>
            <Icons className="icons-footer" />
          </div>
        </div>
        <div className="mobile-footer">
          <Icons className="icons-small" />
        </div>
      </section>
    </>
  )
}

export default Home
